import { useCallback, useMemo } from 'react';
import { useUnit } from 'effector-react';
import { getIn } from '../lib/getIn';
import { Control, ControlComponent } from '../types/control';
import { MemoControl } from './MemoControl';

export function withControl<T>(Component: ControlComponent<T>): Control<T> {
  return function ({
    form,
    label = null,
    name,
    required = false,
    readOnly = false,
    disabled = false,
    ...props
  }) {
    const { values, errors, editable, reset, setValue: setValueForm } = useUnit(form);

    const thisValue = getIn(values, name);
    const value = useMemo(() => getIn(values, name), [thisValue, name]);

    const error = useMemo(() => (errors ? errors[name] : null), [errors]);
    const isError = useMemo(() => Boolean(error), [error]);

    const setValue = useCallback(
      (newValue: any) => setValueForm({ path: name, value: newValue }),
      []
    );

    return (
      <MemoControl
        Component={Component}
        value={value}
        error={error}
        isError={isError}
        editable={editable}
        label={label}
        required={required}
        disabled={disabled}
        readOnly={!editable || readOnly}
        setValue={setValue}
        reset={reset}
        name={name}
        {...props}
      />
    );
  };
}
