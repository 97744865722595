import * as React from 'react';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
  Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Listbox } from '@features/effector-form/components/listbox';
import { withControl } from '@features/effector-form/hoc/withControl';
import * as Styled from './styled';
import { SelectControlType, SelectOption } from './types';

export const MultipleSelectControl = withControl<SelectControlType>(
  ({
    value,
    setValue,
    error,
    label,
    required,
    readOnly,
    disabled,
    options,
    placeholder,
    isError,
    optionalText,
    notFulfilledText,
  }) => (
    <FormControl required={required} disabled={disabled}>
      {label && <FormLabel error={isError}>{label}</FormLabel>}

      <Styled.Autocomplete
        multiple
        value={value}
        onChange={(_: unknown, newValue: SelectOption[]) => {
          setValue(newValue.map((item) => item));
        }}
        options={!options ? [] : options}
        ListboxComponent={Listbox}
        disableClearable
        renderInput={(params): JSX.Element => (
          <Styled.TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              readOnly,
              placeholder,
              error: isError,
              endAdornment: readOnly ? null : params.InputProps.endAdornment,
            }}
          />
        )}
        readOnly={readOnly}
        disabled={disabled}
        isOptionEqualToValue={(option, value) =>
          option.id === value.id && option.label === value.label
        }
        {...(readOnly &&
          !value?.length && {
            renderInput: () => (
              <Styled.Placeholder>{notFulfilledText}</Styled.Placeholder>
            ),
          })}
      />

      {error && <FormHelperText error>{error}</FormHelperText>}
      {optionalText && <FormHelperText>{optionalText}</FormHelperText>}
    </FormControl>
  )
);
