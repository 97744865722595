import metricsApi from '@api/apiMetrics';
import { GetComplexesPayload } from '@features/effector-form/controls/new-complex-controls/types';

const getNewComplexes = (payload: GetComplexesPayload) =>
  metricsApi.get('object/list/short', {
    ...payload,
    ...{ output_object_type: ['complex'] },
  });

export const newComplexesApi = { getNewComplexes };
