import { FormControl, FormHelperText, FormLabel, OutlinedInput } from '@mui/material';
import { withControl } from '@features/effector-form/hoc/withControl';
import * as Styled from './styled';

type Props = {
  minRows?: number;
  maxRows?: number;
  showLength?: boolean;
  maxLength?: number;
  optionalText?: string;
};

export const TextareaControl = withControl<Props>(
  ({
    value,
    setValue,
    error,
    label,
    required,
    readOnly,
    disabled,
    isError,
    minRows = 3,
    maxRows,
    showLength,
    maxLength,
    optionalText,
  }) => (
    <FormControl required={required} disabled={disabled}>
      {label && <FormLabel error={isError}>{label}</FormLabel>}
      <OutlinedInput
        value={value}
        onChange={(e) => setValue(e.target.value)}
        multiline
        minRows={readOnly ? minRows : maxRows ?? minRows}
        maxRows={maxRows}
        readOnly={readOnly}
        disabled={disabled}
        sx={{ padding: 0 }}
        error={!!error}
      />
      <Styled.HelperWrapper>
        {isError ? (
          <FormHelperText error>{error}</FormHelperText>
        ) : (
          !readOnly &&
          showLength &&
          maxLength && <FormHelperText>{optionalText}</FormHelperText>
        )}
        {!readOnly && showLength && maxLength && (
          <FormHelperText error={value.length > maxLength}>
            {`${String(value.length)}/${maxLength}`}
          </FormHelperText>
        )}
      </Styled.HelperWrapper>
    </FormControl>
  )
);
