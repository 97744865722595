import { useGate, useUnit } from 'effector-react';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
  CircularProgress,
} from '@mui/material';
import { Listbox } from '@features/effector-form/components/listbox';
import {
  Complex,
  Props,
} from '@features/effector-form/controls/new-complex-controls/types';
import { withControl } from '@features/effector-form/hoc/withControl';
import { NewComplexesControlGate, newComplexesQuery } from '../model';

export const NewComplexesControl = withControl<Props>(
  ({ value, setValue, error, label, required, readOnly, disabled, cities = [] }) => {
    useGate(NewComplexesControlGate, { cities });

    const { data, pending } = useUnit(newComplexesQuery);

    return (
      <FormControl required={required} disabled={disabled}>
        <FormLabel>{label}</FormLabel>
        <Autocomplete
          multiple
          loading={pending}
          value={!data ? [] : data.filter((item) => value.indexOf(item.id) > -1)}
          options={data && cities.length > 0 ? data : []}
          onChange={(_: unknown, newValue: any) => {
            setValue(newValue.map((item: Complex) => item.id));
          }}
          ListboxComponent={Listbox}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                readOnly,
                endAdornment: readOnly ? null : (
                  <>
                    {pending && <CircularProgress size={20} color="inherit" />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          readOnly={readOnly}
          disabled={disabled}
        />
        <FormHelperText error>{error}</FormHelperText>
      </FormControl>
    );
  }
);
