import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { cache, createQuery, localStorageCache } from '@farfetched/core';
import { signout } from '@features/common';
import { newComplexesApi } from '@features/effector-form/controls/new-complex-controls/api';
import {
  GetComplexesPayload,
  GetComplexesResponse,
} from '@features/effector-form/controls/new-complex-controls/types';

export const NewComplexesControlGate = createGate();

const fxGetNewComplexes = createEffect<
  GetComplexesPayload,
  GetComplexesResponse,
  Error
>().use(newComplexesApi.getNewComplexes);

export const newComplexesQuery = createQuery({
  name: 'complexes',
  effect: fxGetNewComplexes,
  mapData: ({
    result: {
      type: { complex },
    },
  }) => {
    if (!complex || !Object.values(complex)?.length) {
      return [];
    }

    return Object.entries(complex).reduce(
      (acc, [id, label]) => [...acc, { id, label }],
      []
    );
  },
});

cache(newComplexesQuery, {
  adapter: localStorageCache({ maxAge: '5m' }),
  purge: signout,
});

sample({
  clock: NewComplexesControlGate.state,
  fn: ({ cities }) => ({
    object_id: cities?.length ? cities : [],
  }),
  target: newComplexesQuery.start,
});
