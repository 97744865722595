import { FC, useState } from 'react';
import { Popover, Button } from '@mui/material';

// import { IconButton } from '@ui/index';

export const HiddenArray = ({ children }) => {
  if (!children.length) return null;

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (children.length == 1) return children;
  else
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto auto',
          justifyContent: 'start',
          alignContent: 'center',
        }}
      >
        <div style={{ display: 'grid', alignItems: 'center' }}>{children[0]}</div>

        {
          <>
            <Button
              variant="outlined"
              size="small"
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              style={{ minWidth: 'auto', margin: '0 10px' }}
            >
              <span>+{children.slice(1).length}</span>
            </Button>

            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: 'none',
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <div
                style={{
                  display: 'grid',
                  gap: 10,
                  background: '#fcfcfc',
                }}
              >
                {children.slice(1).map((child) => child)}
              </div>
            </Popover>
          </>
        }
      </div>
    );
};
