import { Tooltip } from '@mui/material';
import LoopIcon from '@mui/icons-material/Loop';
import i18n from '@shared/config/i18n';
import { ActionIconButton } from '../..';

const { t } = i18n;

const getActivity = ({ columns, tableData, hiddenColumnNames }) => {
  const withoutHiddenColumns = !columns && !hiddenColumnNames;

  let hasLeastOneVisibleColumn = false;
  let dataExists = false;

  if (!withoutHiddenColumns) {
    hasLeastOneVisibleColumn = columns.length !== hiddenColumnNames.length;
  }

  if (tableData) {
    dataExists = tableData.length;
  }
  return (withoutHiddenColumns || hasLeastOneVisibleColumn) && dataExists;
};

const ChangeEmployeeButton = (props) => {
  const {
    onClick = () => {},
    columns,
    tableData,
    hiddenColumnNames,
    active = false,
    title = null,
    ...rest
  } = props;

  const isActive =
    active == false ? getActivity({ columns, tableData, hiddenColumnNames }) : true;

  return (
    <Tooltip title={title ? title : t('export')}>
      <div>
        <ActionIconButton
          data-test-id="export-btn"
          onClick={onClick}
          disabled={!isActive}
          {...rest}
        >
          <LoopIcon />
        </ActionIconButton>
      </div>
    </Tooltip>
  );
};

export { ChangeEmployeeButton };
