import { memo, useMemo } from 'react';
import { GeneralControlProps, MemoControlType } from '../types/control';

export const MemoControl = memo(function (
  props: MemoControlType<any> & GeneralControlProps
) {
  const {
    Component,
    label,
    value,
    error,
    editable,
    required,
    readOnly,
    disabled,
    setValue,
    isError,
    helperText,
  } = props;

  return useMemo(
    () => (
      <Component
        {...props}
        label={label}
        value={value}
        setValue={setValue}
        error={error}
        isError={isError}
        required={required}
        disabled={disabled}
        readOnly={!editable || readOnly}
        helperText={helperText}
      />
    ),
    [
      label,
      value,
      error,
      editable,
      required,
      readOnly,
      disabled,
      Object.keys(props).length ? props : null,
    ]
  );
});
