import styled from '@emotion/styled';

export const StyledNewsCard = styled.div`
  padding: 24px;

  ul {
    list-style-type: initial;
  };

  figure {
    width: 100%;
    margin: 1rem auto;
  };

  table {
    width: 100%;
  };

  table, th, td {
    border: 1px solid #9e9e9e;
    border-collapse: collapse;
  };

  img {
    max-width: 100%;
    object-fit: contain;
    height: auto;
  };

  .image,.image_resized {
    text-align: center;
  }

  .image-style-block-align-right {
    text-align: right;
  }

  .image-style-block-align-left {
    text-align: left;
  }

  .image.image_resized.image-style-block-align-left{
    margin-right: 50%;
  }

  .image.image_resized.image-style-block-align-right{
    margin-left: 50%;
  }
`;
