import metricsApi from '@api/apiMetrics';
import { GetBuildingsPayload } from '@features/effector-form/controls/new-building-controls/types';

const getNewBuildings = (payload: GetBuildingsPayload) =>
  metricsApi.get('object/list/short', {
    ...payload,
    ...{ output_object_type: ['building'] },
  });

export const newBuildingsApi = { getNewBuildings };
