import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { cache, createQuery, localStorageCache } from '@farfetched/core';
import { signout } from '@features/common';
import { formatPhone } from '@tools/formatPhone';
import * as api from './api';
import { GetRentersResponse } from './types';

export const RentersControlGate = createGate();

const fxGetRenters = createEffect<void, GetRentersResponse[], Error>().use(
  api.getRenters
);

export const rentersQuery = createQuery({
  name: 'renters',
  effect: fxGetRenters,
  mapData: ({ result }) =>
    Array.isArray(result)
      ? result.map((item) => ({
          id: item.userdata_id,
          label: `${item?.surname ? `${item.surname} ` : ''}${
            item?.firstname ? `${item.firstname} ` : ''
          }${item?.patronymic ? `${item.patronymic} ` : ''}`,
          phone: item?.phone ? formatPhone(item.phone) : '',
          email: item?.email ?? '',
          enterprise: item?.enterprise_name ?? '',
        }))
      : [],
});

cache(rentersQuery, {
  adapter: localStorageCache({ maxAge: '5m' }),
  purge: signout,
});

sample({
  clock: RentersControlGate.state,
  source: RentersControlGate.status,
  filter: (status) => Boolean(status),
  target: rentersQuery.start,
});
