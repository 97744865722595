import { useGate, useUnit } from 'effector-react';
import {
  FormControl,
  FormLabel,
  Autocomplete,
  TextField,
  Typography,
  CircularProgress,
  FormHelperText,
} from '@mui/material';
import { Listbox } from '@features/effector-form/components/listbox';
import { withControl } from '../../hoc/withControl';
import { RentersControlGate, rentersQuery } from './model';
import * as Styled from './styled';
import { Renter } from './types';

export const RentersControl = withControl(
  ({ value, setValue, error, label, required, readOnly, disabled, isError }) => {
    useGate(RentersControlGate);
    const { data, pending } = useUnit(rentersQuery);

    const options = Array.isArray(data) ? data : [];

    const currentValue =
      options.length && value
        ? options.find(
            (item: Renter) => item?.id === (typeof value === 'object' ? value?.id : value)
          )
        : null;

    const handleChange = (_: unknown, newValue: Renter | null) =>
      setValue(newValue || null);

    return (
      <FormControl>
        {label && (
          <FormLabel required={required} disabled={disabled} error={isError}>
            {label}
          </FormLabel>
        )}
        <Autocomplete
          loading={pending}
          value={currentValue}
          options={options}
          onChange={handleChange}
          ListboxComponent={Listbox}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                readOnly,
                error: isError,
                endAdornment: readOnly ? null : (
                  <>
                    {pending && <CircularProgress size={20} color="inherit" />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          renderOption={({ key, ...props }, option) => {
            const isExistEmailComma = Boolean(option?.enterprise);
            const isExistPhoneComma = Boolean(option?.email || isExistEmailComma);

            return (
              <li key={key} {...props}>
                <Styled.Option>
                  <Typography variant="body2" color="text.primary">
                    {option.label}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {`${
                      option?.phone
                        ? `${option.phone}${isExistPhoneComma ? ', ' : ''}`
                        : ''
                    }${
                      option?.email
                        ? `${option.email}${isExistEmailComma ? ', ' : ''}`
                        : ''
                    }${option.enterprise ?? ''}`}
                  </Typography>
                </Styled.Option>
              </li>
            );
          }}
          readOnly={readOnly}
          disabled={disabled}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  }
);
