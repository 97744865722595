import { Typography } from '@mui/material';
import * as Styled from './styled';

export const BmsName = ({ name }: { name?: string }) => {
  if (!name) return null;

  return (
    <Styled.Container>
      <Typography variant="subtitle2" color="primary.main">
        {name}
      </Typography>
    </Styled.Container>
  );
};
