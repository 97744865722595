import { ApartmentControl } from '@features/effector-form/controls/apartment';
import { AsyncAutocompleteControl } from '@features/effector-form/controls/async-autocomplete';
import { BuildingControl } from '@features/effector-form/controls/building-controls/building';
import { BuildingsControl } from '@features/effector-form/controls/building-controls/buildings';
import { BuildingsFilterControl } from '@features/effector-form/controls/buildings-filter-control';
import { CheckboxControl } from '@features/effector-form/controls/checkbox';
import { CitiesControl } from '@features/effector-form/controls/city-controls/cities';
import { CityControl } from '@features/effector-form/controls/city-controls/city';
import { ComplexControl } from '@features/effector-form/controls/complex';
import { ComplexesControl } from '@features/effector-form/controls/complexes';
import { CountControl } from '@features/effector-form/controls/count';
import { DateControl } from '@features/effector-form/controls/date';
import { DatePickerControl } from '@features/effector-form/controls/date-picker';
import { DateRangeControl } from '@features/effector-form/controls/dateRange';
import { DateTimeControl } from '@features/effector-form/controls/dateTime';
import { DateTimePickerControl } from '@features/effector-form/controls/dateTimePicker';
import { DateTimeRangeControl } from '@features/effector-form/controls/dateTimeRange';
import { EmailControl } from '@features/effector-form/controls/email';
import { EnterpriseControl } from '@features/effector-form/controls/enterprise';
import { EnterpriseConfirmationModeControl } from '@features/effector-form/controls/enterprise-confirmation-mode';
import { EnterprisePositionControl } from '@features/effector-form/controls/enterprisePosition';
import { GroupControl } from '@features/effector-form/controls/group';
import { MonthControl } from '@features/effector-form/controls/month';
import { MultipleSelectControl } from '@features/effector-form/controls/multiple-select';
import { NewBuildingsControl } from '@features/effector-form/controls/new-building-controls/new-buildings';
import { NewCitiesControl } from '@features/effector-form/controls/new-city-controls/new-cities';
import { NewComplexesControl } from '@features/effector-form/controls/new-complex-controls/new-complexes';
import { NumberControl } from '@features/effector-form/controls/number';
import { ObjectControl } from '@features/effector-form/controls/object';
import { ObjectShareControl } from '@features/effector-form/controls/objectShare';
import { ObjectTypeControl } from '@features/effector-form/controls/objectType';
import { OkvedControl } from '@features/effector-form/controls/okved';
import { OutlinedSelectControl } from '@features/effector-form/controls/outlined-select';
import { PasswordControl } from '@features/effector-form/controls/password';
import { PersonalAccountControl } from '@features/effector-form/controls/personalAccountType';
import { PhoneControl } from '@features/effector-form/controls/phone';
import { PropertiesControl } from '@features/effector-form/controls/properties-control';
import { PropertiesForRentControl } from '@features/effector-form/controls/properties-for-rent';
import { PropertyTypeControl } from '@features/effector-form/controls/property-type-control/property-type';
import { PropertyTypesControl } from '@features/effector-form/controls/property-type-control/property-types';
import { RadioGroupControl } from '@features/effector-form/controls/radioGroup';
import { RentBuildingControl } from '@features/effector-form/controls/rent-building-controls/rent-building';
import { RentBuildingsControl } from '@features/effector-form/controls/rent-building-controls/rent-buildings';
import { RentComplexControl } from '@features/effector-form/controls/rent-complex-controls/rent-complex';
import { RentComplexesControl } from '@features/effector-form/controls/rent-complex-controls/rent-complexes';
import { RentFloorControl } from '@features/effector-form/controls/rent-floor-controls/rent-floor';
import { RentFloorsControl } from '@features/effector-form/controls/rent-floor-controls/rent-floors';
import { RentObjectsControl } from '@features/effector-form/controls/rent-objects';
import { RentPaymentControl } from '@features/effector-form/controls/rent-payment';
import { RentStatusControl } from '@features/effector-form/controls/rent-status-controls/rent-status';
import { RentStatusesControl } from '@features/effector-form/controls/rent-status-controls/rent-statuses';
import { RentTimeControl } from '@features/effector-form/controls/rent-time';
import { RentersControl } from '@features/effector-form/controls/renters';
import { ResidentStatusControl } from '@features/effector-form/controls/residentStatus';
import { SearchControl } from '@features/effector-form/controls/search';
import { SelectControl } from '@features/effector-form/controls/select';
import { SmsPassModeControl } from '@features/effector-form/controls/sms-pass-mode';
import { StoryFrameButtonSectionControl } from '@features/effector-form/controls/storyFrameButtonSection';
import { StoryFrameButtonTypeControl } from '@features/effector-form/controls/storyFrameButtonType';
import { StoryStatusControl } from '@features/effector-form/controls/storyStatus';
import { SwitchControl } from '@features/effector-form/controls/switch';
import { TextControl } from '@features/effector-form/controls/text';
import { TextareaControl } from '@features/effector-form/controls/textarea';
import { TicketClassControl } from '@features/effector-form/controls/ticketClass';
import { TicketCostTypesControl } from '@features/effector-form/controls/ticketCostTypes';
import { TicketOriginControl } from '@features/effector-form/controls/ticketOrigin';
import { TicketPrepaymentTypesControl } from '@features/effector-form/controls/ticketPrepaymentTypes';
import { TicketPriorityControl } from '@features/effector-form/controls/ticketPriority';
import { TicketStatusControl } from '@features/effector-form/controls/ticketStatus';
import { TicketTypeControl } from '@features/effector-form/controls/ticketType';
import { TicketTypeDurationControl } from '@features/effector-form/controls/ticketTypeDuration';
import { TicketTypesLevelControl } from '@features/effector-form/controls/ticketTypesLevel';
import { TimeControl } from '@features/effector-form/controls/time';
import { TimeRangeControl } from '@features/effector-form/controls/timeRange';
import { TimezoneControl } from '@features/effector-form/controls/timezone';
import { UploadDropzoneSingleFileControl } from '@features/effector-form/controls/uploadDropzoneSingleFile';
import { UploadSingleFileControl } from '@features/effector-form/controls/uploadSingleFile';

export const Control = {
  AsyncAutocomplete: AsyncAutocompleteControl,
  Complex: ComplexControl,
  Complexes: ComplexesControl,
  Building: BuildingControl,
  Buildings: BuildingsControl,
  Apartment: ApartmentControl,
  Checkbox: CheckboxControl,
  Email: EmailControl,
  Group: GroupControl,
  Password: PasswordControl,
  Phone: PhoneControl,
  Switch: SwitchControl,
  Text: TextControl,
  Date: DateControl,
  DateRange: DateRangeControl,
  Time: TimeControl,
  TimeRange: TimeRangeControl,
  DateTime: DateTimeControl,
  DateTimeRange: DateTimeRangeControl,
  Number: NumberControl,
  TicketClass: TicketClassControl,
  TicketStatus: TicketStatusControl,
  TicketType: TicketTypeControl,
  TicketPriority: TicketPriorityControl,
  TicketOrigin: TicketOriginControl,
  Textarea: TextareaControl,
  Count: CountControl,
  Select: SelectControl,
  Search: SearchControl,
  RadioGroup: RadioGroupControl,
  ResidentStatus: ResidentStatusControl,
  ObjectType: ObjectTypeControl,
  OutlinedSelect: OutlinedSelectControl,
  EnterprisePosition: EnterprisePositionControl,
  Enterprise: EnterpriseControl,
  TicketTypesLevel: TicketTypesLevelControl,
  TicketTypeDuration: TicketTypeDurationControl,
  TicketPrepaymentTypes: TicketPrepaymentTypesControl,
  TicketCostTypes: TicketCostTypesControl,
  Cities: CitiesControl,
  NewCities: NewCitiesControl,
  RentStatuses: RentStatusesControl,
  RentComplexes: RentComplexesControl,
  NewComplexes: NewComplexesControl,
  RentFloors: RentFloorsControl,
  PropertyTypes: PropertyTypesControl,
  RentPayment: RentPaymentControl,
  City: CityControl,
  RentComplex: RentComplexControl,
  PropertyType: PropertyTypeControl,
  RentStatus: RentStatusControl,
  RentFloor: RentFloorControl,
  RentBuilding: RentBuildingControl,
  RentBuildings: RentBuildingsControl,
  NewBuildings: NewBuildingsControl,
  RentObjects: RentObjectsControl,
  RentTime: RentTimeControl,
  ObjectShare: ObjectShareControl,
  PersonalAccount: PersonalAccountControl,
  Object: ObjectControl,
  Month: MonthControl,
  MultipleSelect: MultipleSelectControl,
  BuildingsFilter: BuildingsFilterControl,
  Properties: PropertiesControl,
  Okved: OkvedControl,
  EnterpriseConfirmationMode: EnterpriseConfirmationModeControl,
  SmsPassMode: SmsPassModeControl,
  PropertiesForRent: PropertiesForRentControl,
  StoryStatus: StoryStatusControl,
  Timezone: TimezoneControl,
  UploadDropzoneSingleFile: UploadDropzoneSingleFileControl,
  UploadSingleFile: UploadSingleFileControl,
  StoryFrameButtonType: StoryFrameButtonTypeControl,
  StoryFrameButtonSection: StoryFrameButtonSectionControl,
  DateTimePicker: DateTimePickerControl,
  DatePicker: DatePickerControl,
  Renters: RentersControl,
};
