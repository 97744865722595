import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { cache, createQuery, localStorageCache } from '@farfetched/core';
import { signout } from '@features/common';
import { newBuildingsApi } from '@features/effector-form/controls/new-building-controls/api';
import {
  GetBuildingsPayload,
  GetBuildingsResponse,
} from '@features/effector-form/controls/new-building-controls/types';

export const NewBuildingsControlGate = createGate();

const fxGetNewBuildings = createEffect<
  GetBuildingsPayload,
  GetBuildingsResponse,
  Error
>().use(newBuildingsApi.getNewBuildings);

export const newBuildingsQuery = createQuery({
  name: 'buildings',
  effect: fxGetNewBuildings,
  mapData: ({
    result: {
      type: { building },
    },
  }) => {
    if (!building || !Object.values(building)?.length) {
      return [];
    }

    return Object.entries(building).reduce(
      (acc, [id, label]) => [...acc, { id, label }],
      []
    );
  },
});

cache(newBuildingsQuery, {
  adapter: localStorageCache({ maxAge: '5m' }),
  purge: signout,
});

sample({
  clock: NewBuildingsControlGate.state,
  fn: ({ complexes }) => ({
    object_id: complexes?.length ? complexes : [],
  }),
  target: newBuildingsQuery.start,
});
