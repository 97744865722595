import {
  Autocomplete as AutocompleteMUI,
  TextField as TextFieldMUI,
  Typography,
  styled,
} from '@mui/material';

export const Autocomplete = styled(AutocompleteMUI)(() => ({
  '& .MuiInputBase-root.MuiInputBase-readOnly': {
    paddingLeft: 'unset',
  },
}));

export const TextField = styled(TextFieldMUI)(({ theme }) => ({
  '& .MuiInputBase-input.MuiInputBase-readOnly': {
    paddingLeft: 'unset',
    color: theme.palette.text.disabled,
  },
}));

export const Placeholder = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: theme.palette.text.disabled,
}));
