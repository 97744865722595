import { FC, SyntheticEvent, useMemo } from 'react';
import { useGate, useUnit } from 'effector-react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  $expandedObjects,
  $objectsTree,
  $selectedObjects,
  ObjectTreeGate,
  setExpandedObjects,
  setSelectedObjects,
} from '@features/effector-form/controls/object/model';
import { ObjectMeta } from '@features/effector-form/controls/object/types/common';
import { ObjectsTreeItem } from './objects-tree-item';
import * as Styled from './styled';

export const ObjectTree: FC = () => {
  useGate(ObjectTreeGate);

  const [objectsTree, expandedObjects, selectedObjects] = useUnit([
    $objectsTree,
    $expandedObjects,
    $selectedObjects,
  ]);

  const handleExpandClick = (event: SyntheticEvent, nodeIds: string[] | string) => {
    setExpandedObjects(typeof nodeIds === 'string' ? [nodeIds] : nodeIds);
  };

  const handleSelectClick = (
    event: SyntheticEvent,
    nodeIds: string[] | string | null
  ) => {
    if (!nodeIds) return;

    setSelectedObjects(typeof nodeIds === 'string' ? [nodeIds] : nodeIds);
  };

  const createTreeItems = (objects: ObjectMeta[]) => {
    if (!objects || !objects.length) return null;

    const createTree = (object: ObjectMeta) => (
      // @ts-ignore
      <ObjectsTreeItem key={object.guid} object={object}>
        {
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          createTreeItems(object.children)
        }
      </ObjectsTreeItem>
    );

    return objects.map((object) => createTree(object));
  };

  const renderTree = useMemo(() => createTreeItems(objectsTree), [objectsTree]);

  return (
    <div>
      {objectsTree.length ? (
        <Styled.ObjectsTree
          slots={{ collapseIcon: ExpandMoreIcon, expandIcon: ChevronRightIcon }}
          expandedItems={expandedObjects}
          selectedItems={selectedObjects}
          onExpandedItemsChange={handleExpandClick}
          onSelectedItemsChange={handleSelectClick}
        >
          {renderTree}
        </Styled.ObjectsTree>
      ) : (
        <div />
      )}
    </div>
  );
};
