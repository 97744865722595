import { ChangeEvent } from 'react';
import {
  FormHelperText,
  FormLabel,
  OutlinedInput,
  OutlinedInputProps,
} from '@mui/material';
import { withControl } from '@features/effector-form/hoc/withControl';
import * as Styled from './styled';

export const TextControl = withControl<OutlinedInputProps>(
  ({
    label,
    value,
    setValue,
    error,
    isError,
    required,
    readOnly,
    disabled,
    helperText,
    inputProps,
    ...props
  }) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value);

    return (
      <Styled.CustomFormControl>
        {label && (
          <FormLabel required={!readOnly && required} error={isError}>
            {label}
          </FormLabel>
        )}
        <OutlinedInput
          {...props}
          value={value}
          error={isError}
          onChange={handleChange}
          required={required}
          readOnly={readOnly}
          disabled={disabled}
          inputProps={inputProps}
        />
        {helperText && !readOnly && !error && (
          <FormHelperText>{helperText}</FormHelperText>
        )}
        {error && <FormHelperText error>{error}</FormHelperText>}
      </Styled.CustomFormControl>
    );
  }
);
