import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { cache, createQuery, localStorageCache } from '@farfetched/core';
import { signout } from '@features/common';
import { CitiesResponse } from '@features/effector-form/controls/city-controls/types';
import { newCitiesApi } from '@features/effector-form/controls/new-city-controls/api';

export const NewCitiesControlGate = createGate();

const fxGetNewCities = createEffect<void, CitiesResponse, Error>().use(
  newCitiesApi.getNewCities
);

export const newCitiesQuery = createQuery({
  name: 'cities',
  effect: fxGetNewCities,
  mapData: ({
    result: {
      type: { region },
    },
  }) => {
    if (!region || !Object.values(region)?.length) {
      return [];
    }

    return Object.entries(region).reduce(
      (acc, [id, label]) => [...acc, { id, label }],
      []
    );
  },
});

cache(newCitiesQuery, {
  adapter: localStorageCache({ maxAge: '5m' }),
  purge: signout,
});

sample({
  clock: NewCitiesControlGate.state,
  target: newCitiesQuery.start,
});
