import { http } from './http';

const isValidUrl = (url) => typeof url === 'string';

const metricsApi = {
  get: (url, ...args) => {
    if (!isValidUrl(url)) {
      return Promise.reject(new Error('Ошибка соединения'));
    }

    return http.get(`${process.env.METRICS_URL}/v0/${url}`, ...args).then((response) => {
      if (response.data.error) {
        throw response.data.message;
      }

      return response.data.data !== undefined ? response.data.data : response.data;
    });
  },
};

export default metricsApi;
