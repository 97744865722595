import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGate, useUnit } from 'effector-react';
import {
  FormHelperText,
  FormLabel,
  TextField,
  CircularProgress,
  Button,
} from '@mui/material';
import { Listbox } from '@features/effector-form/components/listbox';
import { BuildingControlProps } from '@features/effector-form/controls/building-controls/types';
import { Complex } from '@features/effector-form/controls/complexes/types';
import { withControl } from '@features/effector-form/hoc/withControl';
import { BuildingControlGate, buildingQuery } from '../model';
import * as Styled from './styled';

export const BuildingsControl = withControl<BuildingControlProps>(
  ({
    value,
    setValue,
    error,
    label,
    required,
    readOnly,
    disabled,
    complex,
    complexes,
    addAllButton,
    isError,
  }) => {
    useGate(BuildingControlGate, { complex });
    const { t } = useTranslation();

    const { data, pending } = useUnit(buildingQuery);

    const getOptions = () => {
      if (!data) return [];

      if (!complexes?.length && !complex) return data;

      const joinComplexes = complexes ?? [];
      if (complex) joinComplexes.push(complex);

      return data.filter(({ complexId }) => joinComplexes.includes(complexId));
    };

    const options = getOptions();
    const getValue = () => options.filter(({ id }) => value.includes(id));

    const values = getValue();

    const addAllBuildings = () => {
      setValue(options.map(({ id }) => id));
    };

    useEffect(() => {
      setValue(values.map(({ id }) => id));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pending, readOnly]);

    const showError = !readOnly && !!error;

    return (
      <Styled.FormControl required={required} disabled={disabled}>
        {label && <FormLabel error={showError}>{label}</FormLabel>}
        <Styled.Autocomplete
          multiple
          loading={pending}
          value={values}
          options={options}
          onChange={(event: any, newValue: any) => {
            setValue(newValue.map((item: Complex) => item.id));
          }}
          size="small"
          ListboxComponent={Listbox}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              error={showError}
              InputProps={{
                ...params.InputProps,
                error: isError,
                readOnly,
                endAdornment: readOnly ? null : (
                  <>
                    {pending && <CircularProgress size={20} color="inherit" />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          readOnly={readOnly}
          disabled={disabled}
          loadingText={t('Loading')}
          noOptionsText={t('DataGrid.noResultsOverlayLabel')}
        />
        {addAllButton && !readOnly && (
          <Styled.AllBuildings>
            <Button onClick={addAllBuildings} variant="outlined" size="small">
              {t('AllBuildings')}
            </Button>
          </Styled.AllBuildings>
        )}
        {showError && <FormHelperText error>{error}</FormHelperText>}
      </Styled.FormControl>
    );
  }
);
