import { useCallback } from 'react';
import { useIMask } from 'react-imask';
import { FormHelperText, FormLabel, OutlinedInput } from '@mui/material';
import { withControl } from '@features/effector-form/hoc/withControl';
import * as Styled from './styled';

type AdditionalProps = {
  placeholder?: string;
  clearedReadonlyData?: string;
  endAdornment?: JSX.Element;
};

const mask = new RegExp(/^\d+$/);

export const NumberControl = withControl<AdditionalProps>(
  ({
    value,
    setValue,
    error,
    label,
    required,
    readOnly,
    disabled,
    placeholder,
    clearedReadonlyData,
    helperText,
    endAdornment = null,
    isError,
  }) => {
    const handleAccept = useCallback(
      (newValue: string) => {
        if (newValue !== value || newValue === '') {
          setValue(newValue);
        }
      },
      [value]
    );

    const handleComplete = useCallback(
      (newValue: string) => {
        setValue(newValue);
      },
      [value]
    );

    const masked = useIMask(
      { mask },
      {
        onAccept: handleAccept,
        onComplete: handleComplete,
      }
    );

    const getValue = () => {
      if (!value && value !== 0 && clearedReadonlyData && readOnly) {
        return clearedReadonlyData;
      }

      return value;
    };

    return (
      <Styled.FormControl required={required && !readOnly} disabled={disabled}>
        {label && <FormLabel error={isError}>{label}</FormLabel>}
        <OutlinedInput
          inputRef={masked.ref}
          value={getValue()}
          onFocus={() => {
            if (readOnly) return;

            masked.setValue(String(value));
          }}
          readOnly={readOnly}
          disabled={disabled}
          endAdornment={endAdornment}
          placeholder={!readOnly ? placeholder : undefined}
          error={isError}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
        {error && <FormHelperText error>{error}</FormHelperText>}
      </Styled.FormControl>
    );
  }
);
