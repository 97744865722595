import { $timezone } from '../features/common/model/timezone';
import { http } from './http';

export const buildUrl = (url, version) => {
  if (/http?s:\/\//.test(url)) return url;

  const API_URL = process.env.API_URL;
  const vers = version ? `/${version}` : '';
  const startDiv = url.startsWith('/') ? '' : '/';
  const endDiv = url.endsWith('/') ? '' : '/';

  return `${API_URL}${vers}${startDiv}${url}${endDiv}`;
};

export const buildUrlV0 = (url, version) => {
  if (/http?s:\/\//.test(url)) return url;

  const METRICS_URL = process.env.METRICS_URL;
  const vers = version ? `/${version}` : '';
  const startDiv = url.startsWith('/') ? '' : '/';
  const endDiv = url.endsWith('/') ? '' : '/';

  return `${METRICS_URL}${vers}${startDiv}${url}${endDiv}`;
};

const isValidUrl = (url) => typeof url === 'string';
const isValidRequest = (request) => typeof request === 'function';
const isValidParams = (url, request) => isValidUrl(url) && isValidRequest(request);

const transformArgs = (args) => {
  return [
    {
      ...args[0],
      // Ниже подмешиваем глобальные свойства в первый объект аргументов
      timezone: $timezone.stateRef.current,
    },
    // Возвращаем остальные объекты аргументов
    ...args.slice(1, args.length),
  ];
};

const clear = (method = 'post', url, ...args) => {
  const request = http[method.toLowerCase()];

  const error = args.find((arg) => arg.fullError);

  if (!isValidParams(url, request)) {
    return Promise.reject(new Error('Ошибка соединения'));
  }

  return request(url, ...transformArgs(args)).then((response) => {
    if (response.data.error && error && error.fullError) {
      throw response.data;
    }
    if (response.data.error) {
      throw new Error(response.data.message);
    }

    return response.data.data !== undefined ? response.data.data : response.data;
  });
};

const no_vers = (method = 'post', url, ...args) => {
  const request = http[method.toLowerCase()];

  const error = args.find((arg) => arg?.fullError);

  if (!isValidParams(url, request)) {
    return Promise.reject(new Error('Ошибка соединения'));
  }

  const version_url = buildUrl(url);

  return request(version_url, ...transformArgs(args)).then((response) => {
    if (response.data.error && error && error?.fullError) {
      throw response.data;
    }
    if (response.data.error) {
      throw new Error(response.data.message);
    }

    return response.data.data !== undefined ? response.data.data : response.data;
  });
};

const v0 = (method = 'post', url, ...args) => {
  const request = http[method.toLowerCase()];

  if (!isValidParams(url, request)) {
    return Promise.reject(new Error('Ошибка соединения'));
  }

  const version_url = buildUrlV0(url, 'v0');

  return request(version_url, ...args).then((response) => {
    if (response.data.error) {
      throw response.data.message;
    }

    return response.data.data !== undefined ? response.data.data : response.data;
  });
};

const v1 = (method = 'post', url, ...args) => {
  const request = http[method.toLowerCase()];

  if (!isValidParams(url, request)) {
    return Promise.reject(new Error('Ошибка соединения'));
  }

  const version_url = buildUrl(url, 'v1');

  const collectionURL = ['auth/crm/authenticate', 'auth/rent/region/authenticate'];

  return request(version_url, ...transformArgs(args)).then((response) => {
    if (response.data.error) {
      const { message, request_uuid, data } = response.data;
      if (collectionURL.includes(url)) {
        throw new Error(message);
      } else {
        throw new Error(
          JSON.stringify({
            message,
            request_uuid,
            data,
          })
        );
      }
    }

    return response.data.data !== undefined ? response.data.data : response.data;
  });
};

const v2 = (endpoint, data, params, useJson = true) => {
  return http.v2Post(endpoint, data, params, useJson);
};

const v4 = (method, endpoint, data) => {
  const url = buildUrl(endpoint, 'v4');

  const timezone = $timezone.stateRef.current;

  return http.v4request(method, url, { ...data, ...{ timezone } }).then((response) => {
    if (response.data.error) {
      const { message, request_uuid, data, error } = response.data;

      const msg = message || error?.message;

      throw new Error(
        JSON.stringify({
          message: msg,
          request_uuid,
          data,
        })
      );
    }

    return response.data.data !== undefined ? response.data.data : response.data;
  });
};

export const api = {
  clear,
  no_vers,
  v0,
  v1,
  v2,
  v4,
};
