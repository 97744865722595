import { useRef } from 'react';
import ru from 'date-fns/locale/ru';
import { FormControl, FormHelperText, FormLabel, IconButton } from '@mui/material';
import { CalendarToday, Clear } from '@mui/icons-material';
import { DateTimeField, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { withControl } from '@features/effector-form/hoc/withControl';
import { theme } from '@shared/theme';

export const DateTimeControl = withControl(
  ({ value, setValue, error, label, required, readOnly, disabled, isError }) => {
    const inputRef = useRef(null);

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
        <FormControl required={required} disabled={disabled}>
          {label && <FormLabel error={isError}>{label}</FormLabel>}
          <DateTimeField
            value={value}
            onChange={(newValue) => setValue(newValue)}
            inputRef={inputRef}
            InputProps={{
              readOnly,
              disabled,
              error: isError,
              endAdornment: (
                <>
                  {!readOnly && !!value && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setValue(null);

                        if (!inputRef.current) return;

                        (inputRef.current as HTMLInputElement).focus();
                      }}
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  )}
                  {!readOnly && (
                    <CalendarToday
                      fontSize="small"
                      sx={{ color: theme.palette.action.active }}
                    />
                  )}
                </>
              ),
            }}
            readOnly={readOnly}
            disabled={disabled}
          />
          {error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
      </LocalizationProvider>
    );
  }
);
