import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import {
  CancelButton,
  CloseButton,
  DeleteButton,
  EditButton,
  SaveButton,
  Greedy,
  Toolbar,
  ArchiveButton,
  VideoLibraryButton,
} from '../..';

const styles = {
  margin: {
    margin: '0 5px',
  },
};

function DetailToolbar(props) {
  const {
    classes,
    mode,
    hasError = false,
    isArchived,
    onClose,
    onEdit,
    onSave,
    onDelete,
    onCancel,
    onArchive,
    onVideoLibrary,
    hidden = {},
    title,
    tooltip = {},
    titleStyle = {},
    children,
    onUnarchive,
    ...restProps
  } = props;

  return (
    <Toolbar {...restProps}>
      {hidden.edit ? null : (
        <EditButton
          className={classes.margin}
          onClick={onEdit}
          disabled={mode === 'edit'}
          style={{ order: 10 }}
        />
      )}

      {!hidden.delete && (
        <DeleteButton
          kind="negative"
          className={classes.margin}
          disabled={mode === 'edit'}
          onClick={onDelete}
          style={{ order: 20 }}
        />
      )}
      {onVideoLibrary instanceof Function && !hidden.videoLibrary && (
        <VideoLibraryButton
          kind="positive"
          className={classes.margin}
          disabled={mode === 'edit'}
          onClick={onVideoLibrary}
          style={{ order: 20 }}
        />
      )}

      {!hidden.archive && typeof onArchive === 'function' && (
        <ArchiveButton
          className={classes.margin}
          onClick={onArchive}
          titleAccess={tooltip.archive}
          style={{ order: 20, backgroundColor: '#EC7F00' }}
        />
      )}
      {!hidden.unarchive && typeof onUnarchive === 'function' && (
        <ArchiveButton
          isArchived
          className={classes.margin}
          onClick={onUnarchive}
          titleAccess={tooltip.archive}
          style={{ order: 20, backgroundColor: '#EC7F00' }}
        />
      )}
      {title && (
        <Greedy
          style={{
            order: 30,
            marginLeft: 16,
            marginRight: 16,
            textAlign: 'center',
            flex: '1 100%',
            ...(typeof title === 'string' ? titleStyle : {})
          }}
        >
          {title && typeof title === 'string'
            ? <h1 style={{ fontSize: titleStyle.fontSize || 24, margin: 0 }}>{title}</h1>
            : title}
        </Greedy>
      )}

      <Greedy style={{ order: 50, flexGrow: 13 }} />

      {mode === 'edit' ? (
        <>
          {!hidden.save && (
            <SaveButton
              disabled={hasError}
              className={classes.margin}
              kind="positive"
              onClick={onSave}
              style={{ order: 60 }}
              type={typeof onSave === 'function' ? 'button' : 'submit'}
            />
          )}
          {!hidden.cancel && (
            <CancelButton
              kind="negative"
              className={classes.margin}
              style={{ order: 70 }}
              type={typeof onCancel === 'function' ? 'button' : 'reset'}
              onClick={onCancel}
            />
          )}
        </>
      ) : null}
      {!hidden.close && mode !== 'edit' ? (
        <CloseButton
          className={classNames(classes.margin)}
          onClick={onClose}
          style={{ order: 100 }}
        />
      ) : null}
      {children}
    </Toolbar>
  );
}

const StyledDetailToolbar = withStyles(styles)(DetailToolbar);

export { StyledDetailToolbar as DetailToolbar };
