import { styled } from '@mui/material/styles';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';

export const ObjectsTree = styled(SimpleTreeView)(() => ({
  '& *[class*="focused"]': {
    background: 'white',
  },
  '& *[class*="selected"]': {
    background: '#E1EBFF !important',
  },
}));
